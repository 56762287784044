export default {
  header: {
    search: {
      placeholder: 'Rechercher...',
    },
    menu: {
      logout: 'Log out',
    },
  },

  sidebar: {
    menu: {
      dashboard: 'Dashboard',
      poi: 'Points of Interest',
      announcements: 'Announcements',
      media: 'Media Management',
      navigation: 'Navigation',
      pages: 'Pages',
      categories: 'Categories',
      footer: {
        switch: 'Switch Project',
        help: 'Get Help',
        logout: 'Log out',
      },
    },
  },

  toast: {
    welcome: 'Bentornato, {user}',
    autologout: `Stai per essere disconnesso per inattività.`,
  },
}
