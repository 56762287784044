<template>
  <div class="d-flex flex-column flex-grow-1">
    <PageHeader>
      <template #default>Dashboard</template>
      <template #subtitle>{{ project?.name }}</template>
      <template #message>
        Welcome
        <a href="javascript:void(0)" class="font-w600">{{ user?.full_name }}</a>
        <span>, everything looks great.</span>
      </template>
      <template #right>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item">
            <a class="nav-link disabled">Display</a>
          </li>
          <div class="d-flex flex-row">
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { view: 'analytics' } }">
                Analytics
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { view: 'status' } }">
                Status
              </router-link>
            </li>
          </div>
        </ul>
      </template>
    </PageHeader>
    <template v-if="$route.params.view === 'analytics'">
      <div class="content d-flex flex-grow-1">
        <h4 v-if="!project?.temp_dashboard">Coming Soon</h4>
        <iframe
          v-else
          width="100%"
          height="100%"
          :allowtransparency="true"
          style="background: #ffffff"
          :src="project?.temp_dashboard"
        ></iframe>
      </div>
    </template>
    <template v-if="$route.params.view === 'status'">
      <div class="content flex-grow-1 statuses">
        <div
          :data-last-updated="lastUpdated"
          class="status"
          v-for="status in statuses"
          :class="{ danger: moment(status.last_ping).diff(moment()) < -300000 }"
        >
          <div class="d-flex flex-column flex-grow-1 overflow-hidden w-full">
            <h4 class="m-0 text-nowrap text-truncate">{{ status.kiosk_name }}</h4>
            <h4 class="m-0 text-nowrap text-truncate">{{ status.kiosk_id }}</h4>
            <h6 class="key m-0 mt-3">Last Ping:</h6>
            <span v-if="status.last_ping">{{ moment(status.last_ping).fromNow() }}</span>
            <span v-else class="text-danger">No ping received</span>
            <template v-if="status.screenshot">
              <h6 class="key m-0 mt-3">Last Screenshot:</h6>
              <span>{{ moment(status.screenshot.modified_on ?? status.screenshot.uploaded_on).fromNow() }}</span>
            </template>
            <div class="d-flex flex-column flex-grow-1 align-items-start justify-content-end mt-3">
              <button
                class="btn d-flex btn-rounded text-nowrap"
                :class="{ 'btn-warning': status.restart, 'btn-danger': !status.restart }"
                @click="refreshNow(status.id)"
              >
                <template v-if="status.restart">
                  Refreshing
                  <Spinner class="ms-2" :size="24" />
                </template>
                <template v-else>Refresh now</template>
              </button>
            </div>
          </div>
          <div class="img-wrapper" v-if="status.screenshot">
            <template
              v-if="
                timestamp -
                  Math.max(
                    moment(status.screenshot.modified_on).valueOf(),
                    moment(status.screenshot.uploaded_on).valueOf(),
                  ) <
                2000
              "
            >
              <div class="refresh">
                Updating
                <br />
                Screenshot ...
              </div>
            </template>
            <img
              v-else
              :src="`${mediaBase}/assets/${status.screenshot.id}?lm=${
                status.screenshot.modified_on ?? status.screenshot.uploaded_on
              }`"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'

import { PageHeader, Spinner } from '@avriopolis/common/components'
import Auth from '@/store/auth'
import project from '@/store/project'
import axios from '@/api'
import moment from 'moment'

export default defineComponent({
  name: 'Dashboard',
  emits: ['loading'],
  components: {
    PageHeader,
    Spinner,
  },
  beforeRouteUpdate(to, from) {
    console.log(to.params.view, from.params.view)
    if (to.params.view && from.params.view) {
      if (to.params.view === 'status' && from.params.view !== 'status') this.updateData(true)
    }
  },
  beforeRouteEnter(to, _from, next) {
    if (!to.params.view) {
      return next({ ...to, params: { ...to.params, view: 'analytics' } })
    }
    next()
  },
  setup(props, { emit }) {
    const statuses = ref<Status[]>([])

    const fetchData = async () => {
      const response = await axios.get(
        `/items/status?filter[projects_id]=${project.active?.id}&fields=*,screenshot.id,screenshot.modified_on,screenshot.uploaded_on`,
      )
      return response?.data?.data
    }

    async function refreshNow(id: number) {
      await axios.patch(`/items/status/${id}`, { restart: true })
      updateData()
    }

    async function updateData(loading: boolean = true) {
      loading && emit('loading', true)
      let status = statuses.value
      try {
        status = await fetchData()
      } catch (e) {}
      loading && emit('loading', false)
      statuses.value = status
    }

    updateData()

    const lastUpdated = ref(new Date().getTime())
    const timestamp = ref(new Date().getTime())
    const interval = ref(),
      timer = ref()

    onMounted(() => {
      interval.value = setInterval(() => {
        lastUpdated.value = new Date().getTime()
        updateData()
      }, 60000)

      timer.value = setInterval(() => {
        timestamp.value = new Date().getTime()
      }, 1000)
    })

    onBeforeUnmount(() => {
      clearInterval(interval.value)
    })

    return {
      lastUpdated,
      moment,
      user: Auth.user,
      project: project.active,
      statuses,
      updateData,
      refreshNow,
      timestamp,
      mediaBase: import.meta.env.VITE_API_URL,
    }
  },
})
</script>

<style lang="scss" scoped>
.statuses {
  display: grid;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;

  grid-template-columns: repeat(auto-fill, 460px);
}
.status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  border-radius: 0.2rem;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid #dee2e6 !important;
  max-height: 340px;

  .img-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 160px;
    min-width: 160px;
    max-width: 160px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .refresh {
      display: grid;
      place-items: center;
      height: 100%;
      width: 160px;
      text-align: center;
    }
  }

  &.danger {
    border: 1px solid red !important;
    background: #ffd0d0;
  }
}
</style>
