<template>
  <div class="page-header bg-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-md-row align-items-center justify-content-sm-between gap-3">
        <div>
          <h1 v-if="$slots.default" class="h3 font-w700 my-2 text-center text-sm-start">
            <slot />
            <small
              v-if="$slots.subtitle"
              class="
                d-block d-sm-inline-block
                mt-2 mt-sm-0
                ms-2
                font-size-base font-w400
                text-muted text-center text-sm-start
              "
            >
              <slot name="subtitle" />
            </small>
          </h1>
          <h2 v-if="$slots.message" class="h6 font-w500 text-muted mb-0 text-center text-sm-start">
            <slot name="message" />
          </h2>
        </div>
        <div
          v-if="$slots.right"
          class="
            d-flex
            flex-sm-00-auto
            ms-sm-3
            flex-column flex-sm-row
            gap-3
            flex-grow-1
            align-items-center
            justify-content-end
          "
        >
          <slot name="right" />
        </div>
      </div>

      <div v-if="$slots.bottom" class="d-flex flex-sm-row justify-content-sm-center align-items-sm-center mt-4">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PageHeader',
})
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  #page-container > #main-container .content {
    width: 92%;
  }
}

.page-header {
  position: relative;
}
</style>
