<template>
  <div v-if="organised_groups.length > 0" class="d-flex flex-column flex-grow-1 gap-2 mb-5 border rounded-3 bg-white">
    <div v-for="(group, i) in organised_groups" :key="i" class="group d-flex flex-column px-3">
      <h4 class="border-bottom py-2">{{ group.name }}</h4>
      <div class="field mb-2" v-for="(field, i) in group.fields" :key="i">
        <div class="d-flex align-items-end justify-content-between">
          <h6 class="mb-2">{{ field.fields_id.name }}</h6>
          <button class="btn btn-sm p-1" :class="{ 'text-danger': locked[field.id] }" @click="toggleLock(field)">
            <span class="material-icons">{{ locked[field.id] ? 'lock' : 'lock_open' }}</span>
          </button>
        </div>
        <template v-if="field.fields_id.type === 'wysiwyg'">
          <WYSIWYG :id="field.fields_id.key" height="auto" :value="field.value" @change="updateValues(field, $event)" />
        </template>
        <template v-else-if="field.fields_id.type === 'text'">
          <text-input class="border mb-2" :value="field.value" @change="updateValues(field, $event)" />
        </template>
        <template v-else-if="field.fields_id.type === 'textarea'">
          <text-input component="textarea" class="border mb-2" :value="field.value" @change="updateValues(field, $event)" />
        </template>
        <template v-else-if="field.fields_id.type === 'number'">
          <input
            type="number"
            step="any"
            class="form-control border mb-2"
            :value="`${field.value}`"
            @input="updateValues(field, $event.target.value)"
          />
        </template>
        <template v-else-if="field.fields_id.type === 'boolean'">
          <span>Boolean</span>
        </template>
        <template v-else-if="field.fields_id.type === 'date'">
          <input
            type="datetime-local"
            step="any"
            class="form-control border mb-2"
            :value="`${field.value}`"
            @input="updateValues(field, $event?.target?.value)"
          />
        </template>
        <template v-else-if="field.fields_id.type === 'destination'">
          <Destination indent class="mb-2" :value="field.value ?? undefined" @update="updateValues(field, $event)" />
        </template>
        <template v-else-if="field.fields_id.type === 'json'">
          <span>JSON</span>
        </template>
        <template v-else>
          {{ field.fields_id }}
        </template>
        <hr v-if="i < group.fields.length - 1" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { cloneDeep } from 'lodash'

import WYSIWYG from '@/components/inputs/WYSIWYG.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import Destination from '@/components/inputs/Destination.vue'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'

export default defineComponent({
  name: 'Edit_Fields',
  emits: ['update'],
  props: {
    fields: {
      type: Array as PropType<Array<FieldValue>>,
      required: true,
    },
  },
  components: {
    WYSIWYG,
    TextInput,
    Destination,
    LocalisedValue,
  },
  setup(props, { emit }) {
    const values = ref<{ [id: number]: any }>({})
    const locked = ref<{ [id: number]: boolean }>({})

    for (let field of props.fields ?? []) {
      values.value[field.id] = cloneDeep(field.value)
      locked.value[field.id] = !!field.locked
    }

    const organised_groups = computed(() => {
      const groups: { name: String; fields: FieldValue[] }[] = []
      for (let field of props.fields ?? []) {
        let group = groups.find((g) => g.name === field.fields_id.group)
        if (!group) {
          group = { name: field.fields_id.group, fields: [] }
          groups.push(group)
        }
        group.fields.push({ ...field, value: values.value[field.id] })
      }
      return groups
    })

    function updateValues(field: FieldValue, value: any) {
      values.value[field.id] = value
      emit('update', { id: field.id, value })
    }

    function toggleLock(field: FieldValue) {
      locked.value[field.id] = !locked.value[field.id]
      emit('update', { id: field.id, locked: locked.value[field.id] })
    }

    return { organised_groups, locked, updateValues, toggleLock }
  },
})
</script>
