export default {
  header: {
    search: {
      placeholder: 'Rechercher...',
    },
    menu: {
      logout: 'Se déconnecter',
    },
  },

  sidebar: {
    menu: {
      dashboard: 'Tableau de bord',
      poi: "Points d'intérêt",
      announcements: 'Annonces',
      media: 'Médias',
      navigation: 'Menus',
      pages: 'Pages',
      categories: 'Catégories',
      footer: {
        switch: 'Changer de projet',
        help: 'Support',
        logout: 'Se déconnecter',
      },
    },
  },

  toast: {
    welcome: 'Bienvenue à nouveau, {user}',
    autologout: `Vous êtes sur le point d'être déconnecté pour inactivité.`,
  },
}
