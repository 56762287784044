<template>
  <div class="categories-wrapper p-1" :class="{ small }">
    <div class="d-none d-sm-inline-block">
      <div role="group" class="input-group input-group-sm border rounded-3">
        <input
          type="text"
          placeholder="Search.."
          class="form-control-alt form-control"
          @input="query = $event.target.value"
        />
        <div class="input-group-append">
          <span class="input-group-text bg-light border-0">
            <i class="material-icons md-18">search</i>
          </span>
        </div>
      </div>
    </div>
    <div class="categories flex-grow-1 overflow-auto" data-simplebar>
      <nested
        v-if="categories"
        :values="categories"
        :parent_id="undefined"
        :expanded="expanded"
        :visible="visible"
        :inset="inset"
        :small="small"
      >
        <template #default="{ value, parent }">
          <div
            :data-id="value.id"
            class="item border rounded-3"
            :class="{ parent, small, canNest, open: expanded.includes(value.id) }"
          >
            <div class="item-content-wrapper d-flex flex-grow-1 h-100 align-items-center">
              <div v-if="select" class="form-check form-check-inline m-0 p-0 d-flex align-items-center">
                <input
                  :ref="(el) => setCheckbox(el, value.id)"
                  class="form-check-input m-0 p-0"
                  type="checkbox"
                  @input="toggleCategory(value.id, $event.target.checked)"
                />
              </div>
              <div v-if="lock && activeIds.includes(value.id)" class="d-flex gap-2 mx-2">
                <button class="btn btn-sm" :class="lockedIds.includes(value.id) ? 'text-danger' : 'text-secondary opacity-50'" @click="lockCategory(value.id)">
                  <span class="action material-icons md-18">lock</span>
                </button>
              </div>
              <div
                class="d-flex flex-grow-1 align-items-center overflow-hidden action"
                :class="{ parent, 'justify-content-between': !edit, 'flex-row-reverse': !edit }"
                @click="parent ? toggle(value.id) : toggleCategory(value.id)"
              >
                <span v-if="canNest" class="arrow material-icons" :style="{ opacity: parent ? 1 : 0 }">
                  expand_more
                </span>
                <span class="mx-1 text-truncate overflow-hidden"><localised-value :value="value.name" /></span>
              </div>
            </div>
            <div v-if="edit" class="d-flex gap-2">
              <button class="btn btn-sm btn-outline-primary" @click="editCategory(value)">
                <span class="action material-icons md-18">edit</span>
              </button>
              <button class="btn btn-sm btn-outline-secondary" @click="$emit('create', value?.id)">
                <span class="action material-icons md-18">add</span>
              </button>
            </div>
          </div>
        </template>
      </nested>
    </div>

    <div ref="offcanvasRef" class="offcanvas offcanvas-end" tabindex="-1" id="offcanvas">
      <div class="position-absolute top-0 end-0 p-3">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <h5 class="p-3 m-0" :data-cat="editing?.id">Edit Category</h5>

      <div class="d-flex flex-column px-3">
        <hr class="mt-0" />

        <div class="d-flex justify-content-between align-items-center gap-5">
          <span class="fs-7 fw-bolder text-muted">Status</span>
          <select class="form-select" :value="editing?.status" @input="updateStatus($event.target.value)">
            <option value="published">Published</option>
            <option value="draft">Draft</option>
            <option value="archived">Archived</option>
          </select>
        </div>

        <h6 class="mb-2">Name</h6>
        <text-input class="border mb-2" :value="editing?.name" @change="updateName($event)" />
        <hr />
        <h6 class="mb-2">Description</h6>
        <text-input class="border mb-2" :value="editing?.description" @change="updateDesc($event)" localised />
        <hr />
        <h6 class="mb-2">Icon</h6>
        <div class="d-flex align-items-start justify-content-between gap-3">
          <div class="d-flex flex-column gap-2 justify-content-start align-items-start">
            <input
              class="form-control"
              accept="image/svg+xml"
              type="file"
              @change="updateIcon($event.target.files[0])"
            />
            <button class="btn btn-danger" @click="updateIcon()">Clear</button>
          </div>
          <img v-if="editing?.icon" height="100" :src="`${mediaBase}/assets/${editing?.icon}?key=gallery`" />
        </div>
        <hr />
        <template v-if="hasChanges()">
          <div style="height: 0.5rem"></div>
          <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-outline-primary" @click="saveChanges()">
              <span class="material-icons me-2">save</span>
              <span>Save Changes</span>
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watchEffect } from 'vue'
import { xor, cloneDeep } from 'lodash'
import { Offcanvas } from 'bootstrap'
import axios from '@/api'

import { hasChanges, processChanges, pushChange } from '@/store/changes'
import Nested from '@/components/misc/Nested.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import CheckboxHandler from '@/utils/nestedCheckboxes'
import { useI18n } from 'vue-i18n'
import { isEqual } from 'lodash'

export default defineComponent({
  name: 'CategorList',
  emits: ['categories', 'create', 'update', 'lock'],
  props: {
    lock: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Array as PropType<Array<POICategory>>,
      default: [],
    },
    categories: {
      type: Array as PropType<Array<Category>>,
      default: [],
    },
  },
  components: {
    Nested,
    LocalisedValue,
    TextInput,
  },
  setup(props, { emit }) {
    const i18n = useI18n()
    const query = ref('')
    const editing = ref<Category>(), offcanvasRef = ref(), offcanvas = ref()
    const visible = ref<number[]>()
    const checkboxes = ref<{ [key: string]: HTMLInputElement }>({})
    const canNest = computed(() => !!props.categories?.find((c) => c.parent_id))

    const activeIds = props.active.map((c) => c.categories_id?.id ?? c)
    const lockedIds = ref(props.active.filter((c) => c.locked).map((c) => c.categories_id?.id ?? c))
    const handler = ref(new CheckboxHandler(props.categories, activeIds))
    const expanded = ref<number[]>([...activeIds, activeIds.map((a) => handler.value.getParents(a)).flat()].flat())

    function toggle(id: number) {
      expanded.value = xor(expanded.value, [id])
    }

    function toggleCategory(id: number, state?: boolean) {
      if (state === undefined) {
        state = !handler.value.isChecked(id)
      }
      handler.value.setSelected(id, state)
      emit('categories', [...handler.value.checked])
    }

    function lockCategory(id: number) {
      const locked = !lockedIds.value.includes(id);
      
      if (locked) {
        const lockedIdsNew = [ ...lockedIds.value ];
        lockedIdsNew.push(id);
        lockedIds.value = lockedIdsNew
      } else {
        lockedIds.value = lockedIds.value.filter(c => c !== id);
      }

      emit('lock', {id: id, locked });
    }

    function setCheckbox(el: HTMLInputElement, id: number) {
      checkboxes.value[id] = el
      if (el) {
        el.indeterminate = handler.value.isIndeterminate(id)
        el.checked = handler.value.isSelected(id)
      }
    }

    function editCategory(e: any) {
      if (offcanvas.value) {
        editing.value = cloneDeep(e)
        if (editing.value) {
          offcanvas.value.show()
        }
      }
    }
    
    async function updateName(name?: any) {
      if (editing.value) {
        const old = props.categories.find((c) => c.id === editing.value?.id)
        if (!isEqual(old?.name, name)) {
          editing.value.name = name
          pushChange('Category', editing.value.id, { name })
        } else {
          pushChange('Category', editing.value.id, { name: undefined })
        }
      }
    }

    async function updateStatus(status?: any) {
      if (editing.value) {
        const old = props.categories.find((c) => c.id === editing.value?.id)
        if (!isEqual(old?.status, status)) {
          pushChange('Category', editing.value.id, { status })
        } else {
          pushChange('Category', editing.value.id, { status: undefined })
        }
        editing.value.status = status
      }
    }

    async function updateDesc(description?: any) {
      if (editing.value) {
        const old = props.categories.find((c) => c.id === editing.value?.id)
        if (!isEqual(old?.description, description)) {
          editing.value.description = description
          pushChange('Category', editing.value.id, { description })
        } else {
          pushChange('Category', editing.value.id, { description: undefined })
        }
      }
    }

    async function updateIcon(file?: File) {
      if (editing.value) {
        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          const response = await axios.post('/files', formData)
          if (response.status === 200 && response.data?.data) {
            const fileData = response?.data?.data
            editing.value.icon = fileData.id
            pushChange('Category', editing.value.id, { icon: fileData.id })
          }
        } else {
          editing.value.icon = null
          pushChange('Category', editing.value.id, { icon: null })
        }
      }
    }

    onMounted(() => {
      offcanvas.value = new Offcanvas(offcanvasRef.value)
      offcanvasRef.value.addEventListener('hide.bs.offcanvas', async (e: any) => {
        if (hasChanges()) {
          e.preventDefault()
          if (await processChanges(false)) {
            offcanvas.value.hide()
            emit('update')
          }
        }
      })
    })

    watchEffect(() => {
      if (!query.value) {
        visible.value = undefined
        return
      }

      const categories = []
      cat: for (let category of props.categories) {
        const name = category.name.find((c) => c.lang === i18n.locale.value)
        if (name && name.value.toLowerCase().includes(query.value.toLowerCase())) {
          categories.push(category.id)
          continue cat
        }
      }
      visible.value = categories.map((c) => [c, ...handler.value.getParents(c)]).flat()
    })

    async function saveChanges() {
      if (await processChanges(true)) {
        offcanvas.value?.hide()
        emit('update')
      }
    }

    return {
      visible,
      expanded,
      editing,
      toggle,
      canNest,
      checkboxes,
      setCheckbox,
      toggleCategory,
      query,
      editCategory,
      offcanvasRef,
      updateName,
      updateDesc,
      updateIcon,
      updateStatus,
      hasChanges,
      saveChanges,
      lockedIds,
      activeIds,
      lockCategory,
      mediaBase: import.meta.env.VITE_API_URL,
    }
  },
})
</script>

<style lang="scss" scoped>
.categories-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
  overflow: hidden;
  gap: 0.25rem;

  ::v-deep(.opacity-50) {
    opacity: 0.5;
    filter:alpha(opacity=50);
  }

  .categories {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    position: relative;
    user-select: none;
    flex-wrap: nowrap;

    ::v-deep(.simplebar-content) {
      gap: 0.25rem;

      &::after,
      &::before {
        display: none;
      }
    }

    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: capitalize;

      gap: 1rem;
      height: 3rem;
      padding: 0 1rem;
      background: #ffffff;

      input:indeterminate {
        background-color: gray;
        border-color: gray;
      }

      .form-check-input {
        margin-left: 1rem !important;
      }

      .item-content-wrapper {
        gap: 0.5rem;
        overflow: hidden;
        flex: 1;
      }

      .action {
        cursor: pointer;
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        width: 34px;
        transition: transform 0.125s ease-in-out;
        transform: rotate(-90deg);
      }

      &.open .arrow {
        transform: rotate(0deg);
      }

      .btn {
        padding: 0.25rem !important;
      }

      &.small {
        gap: 0.25rem;
        height: 2rem;
        font-size: 0.75rem;
        padding: 0 0.5rem 0 0.75rem;

        .form-check-input {
          margin-left: 0.5rem !important;
        }

        .item-content-wrapper {
          gap: 0rem;
        }

        .btn {
          padding: 0.125rem !important;
          .material-icons {
            font-size: 16px;
          }
        }
      }

      &.canNest {
        padding: 0 0.5rem 0 0rem;
      }
    }
  }
}
</style>
